









































import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import Agency from "~/models/Agency"
import History from "~/models/History"

@Component({})
export default class AgencyAds extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: true }) agency!: Agency
  @Prop({ default: 2 }) elevation!: number
  @Prop({ default: 1 }) nbColumn!: number

  get associatedAdsCount() {
    return this.agencyAds.length
  }
  get agencyAds() {
    return this.house.history
      .filter(({ agency_id }) => agency_id == this.agency.id)
      .sort((a: History, b: History) => (a.active > b.active ? -1 : 1))
  }
}
